<script>
import Vue from "vue";
import Layout from "../../layouts/main";
import appConfig from "@/app.config";
import vue2Dropzone from "vue2-dropzone";
import Switches from "vue-switches";
import Multiselect from "vue-multiselect";
import simplebar from "simplebar-vue";
import Swal from "sweetalert2";
import {
  required
} from "vuelidate/lib/validators";
import {downFile,iconClass,fileSize,getUUID} from '@/apis/common'
import {uploadFile,deleteFile} from '@/apis/file'
import {queryStaffList} from '@/apis/user'
import {saveNetDiskFile,queryNetDiskFile,deleteNetDiskFile,uniqueName,summaryNetDiskFile,shareFile,deleteShareFile,queryShareList} from '@/apis/disk'
/**
 * Advanced table component
 */
export default {
  page: {
    title: "网盘",
    meta: [{ name: "description", content: appConfig.description }]
  },
  components: { Layout ,vueDropzone: vue2Dropzone,Switches,Multiselect,simplebar},
  validations: {
    form: {
        fileName: { 
            required,
            async isUnique(value){
                if (value.trim().length === 0) return true;
                let usernameUnique;
                let params = {filePath:this.paths[this.paths?.length-1]?.filePath,fileName:value,fileId:this.form.fileId}
                return new Promise(async function (resolve) {
                    await uniqueName(params)
                    .then((response) => {
                        usernameUnique = response.data
                    })
                    resolve(usernameUnique)
                });
            },
        }
    },
  },
  props:{
    filePath:{
        type:String,
        required:false
    }
  },
  data() {
    return {
      title: "网盘",
      summaryObj:[],
      keyword:"",
      showShareModal:false,
      paths: [
        {
          text: "网盘",
          filePath:"/",
          active: true
        }
      ],
      form: {
        fileId:"",
        fileName:"",
        fileOrgName:"",
        fileDescription:"",
        filePath:"",
        fileType:0,
        shareType:0,
        type:true,
        fileUsers:"",
        users:[],
      },
      formOld: null,
      fileDataList: [],
      currentItem:null,
      isEdit:false,
      fileType: "",
      formTitle: "",
      uploadType: "DSK",
      formLoading: false,
      showData: true,
      showType: "list",
      filter: null,
      submitted: false,
      uploadFileList:[],
      staffList:[],
      dropzoneOptions: {
        url: "/api-gateway/service/upload",
        thumbnailWidth: 80,
        maxFilesize: 100,
        addRemoveLinks: true,
        headers: { "Authorization": localStorage.getItem("auth.token")},
        paramName:"files",
        removedfile(file) {
            if (file.previewElement != null && file.previewElement.parentNode != null) {
                file.previewElement.parentNode.removeChild(file.previewElement);
            }
            let fileOrgName = JSON.parse(file.xhr.response).data[0].fileOrgName
            window._pagethis.clearFile(fileOrgName);
            return this._updateMaxFilesReachedClass();
        },
      },
      users:[],
      shareForm:{
        fileId:null,
        shareType:1,
        validDays:-1,
        upload:0,
        pwd:"",
        fileType:0,
        fileName:""
      },
      shareFile:{
        code:"",
        pwd:""
      },
      shareLinkShow:false,
      shareFileList:[],
      firstLoad:true,
    };
  },
  computed: {
    shareLinkText(){
        let text = "绿朴文件分享链接: "+this.shareLink 
        if(this.shareLinkPwd!=''){
            text +=" 提取码:"+this.shareLinkPwd
        }
        return text
    },
    shareLink(){
        if(this.shareFile.id!=''){
            return "http://application.greenpont.com/share-file/"+this.shareFile.id
        }
        return ""
    },
    shareLinkPwd(){
        if(this.shareFile.pwd!=null && this.shareFile.pwd!=''){
            return this.shareFile.pwd
        }
        return ""
    },
    checkPwd(){
        if(this.shareForm.shareType==1 && this.shareForm.pwd==""){
            return false
        }
        return true
    },
    rows() {
      return this.fileDataList.length;
    },
    fileNameLabel(){
        if(this.fileType=="folder"){
            return this.$t("netdisk.folderName")
        }else{
            return this.$t("netdisk.fileName")
        }
    }
  },
  watch: {
    "showForm.shareType":function(newValue){
        if(newValue==1 && this.shareForm.pwd==""){
            this.shareForm.pwd = getUUID().substr(0,4)
        }
    },
    "form.fileName":function(newValue){
        if(newValue!=""){
            this.$v.$touch();
        }
    },
    keyword: function (newKeyword) {
        if(newKeyword==""){
            this.refresh()
        }else{
            this.handleSearch()
        }
    }
  },
  created(){
    this.formOld = {...this.form}
    //初始化用户列表
    queryStaffList(undefined).then((res)=>{
        this.staffList = res.data;
    })
  },
  mounted() {
    window._pagethis = this
    this.refresh();
  },
  methods: {
    getParams(){
        const params = {...this.form};
        params.fileType = this.fileType=="folder"?1:0;
        params.shareType = this.form.type?1:0;
        params.filePath = this.paths[this.paths?.length-1]?.filePath;
        params.fileUsers = this.form.users.map((obj) => {
            return obj.userName
        }).join(",")
        return params;
    },
    handleShareList(){
        this.showData = true
        this.showType = "shareList"
        this.refreshShareList()
    },
    refresh(){
        if(this.showType=="shareList"){
            this.refreshShareList()
            return
        }
        const params = {}
        if(this.firstLoad && this.filePath!=undefined){
            this.firstLoad = false
            params.filter = {filePath:"/"+this.filePath+"/"}
        }else{
            params.filter = {filePath:this.paths[this.paths?.length-1].filePath};
        }
        queryNetDiskFile(params).then((res)=>{
            this.fileDataList = res.data;
        });
        summaryNetDiskFile().then((res)=>{
            this.summaryObj = {}
            for(let i=0;i<res.data.length;i++){
                if(res.data[i]["fileType"].toUpperCase()=="PNG" || res.data[i]["fileType"].toUpperCase()=="JPG" || res.data[i]["fileType"].toUpperCase()=="GIF"){
                    if(this.summaryObj.Images==undefined){
                        this.summaryObj["Images"] = {"fileType":".PNG","size":0,"files":0}
                    }
                    this.summaryObj["Images"].size += res.data[i]["size"]
                    this.summaryObj["Images"].files += res.data[i]["files"]
                }else if(res.data[i]["fileType"].toUpperCase()=="MP4"){
                    if(this.summaryObj.Video==undefined){
                        this.summaryObj["Video"] = {"fileType":".MP4","size":0,"files":0}
                    }
                    this.summaryObj["Video"].size += res.data[i]["size"]
                    this.summaryObj["Video"].files += res.data[i]["files"]
                }else if(res.data[i]["fileType"].toUpperCase()=="MP3"){
                    if(this.summaryObj.Music==undefined){
                        this.summaryObj["Music"] = {"fileType":".MP3","size":0,"files":0}
                    }
                    this.summaryObj["Music"].size += res.data[i]["size"]
                    this.summaryObj["Music"].files += res.data[i]["files"]
                }else if(res.data[i]["fileType"].toUpperCase()=="DOC" || res.data[i]["fileType"].toUpperCase()=="DOCX"){
                    if(this.summaryObj.Document==undefined){
                        this.summaryObj["Document"] = {"fileType":".DOC","size":0,"files":0}
                    }
                    this.summaryObj["Document"].size += res.data[i]["size"]
                    this.summaryObj["Document"].files += res.data[i]["files"]
                }else if(res.data[i]["fileType"].toUpperCase()=="XLS" || res.data[i]["fileType"].toUpperCase()=="XLSX"){
                    if(this.summaryObj.Excel==undefined){
                        this.summaryObj["Excel"] = {"fileType":".XLS","size":0,"files":0}
                    }
                    this.summaryObj["Excel"].size += res.data[i]["size"]
                    this.summaryObj["Excel"].files += res.data[i]["files"]
                }else if(res.data[i]["fileType"].toUpperCase()=="PPT" || res.data[i]["fileType"].toUpperCase()=="PPTX"){
                    if(this.summaryObj.PPT==undefined){
                        this.summaryObj["PPT"] = {"fileType":".PPT","size":0,"files":0}
                    }
                    this.summaryObj["PPT"].size += res.data[i]["size"]
                    this.summaryObj["PPT"].files += res.data[i]["files"]
                }else if(res.data[i]["fileType"].toUpperCase()=="PDF"){
                    if(this.summaryObj.PDF==undefined){
                        this.summaryObj["PDF"] = {"fileType":".PDF","size":0,"files":0}
                    }
                    this.summaryObj["PDF"].size += res.data[i]["size"]
                    this.summaryObj["PDF"].files += res.data[i]["files"]
                }else{
                    if(this.summaryObj.Others==undefined){
                        this.summaryObj["Others"] = {"fileType":".OTHER","size":0,"files":0}
                    }
                    this.summaryObj["Others"].size += res.data[i]["size"]
                    this.summaryObj["Others"].files += res.data[i]["files"]
                }
            }
        })
    },
    submitForm(){
        this.submitted = true;
        this.$v.$touch();
        if (this.$v.$invalid) {
            return;
        } else {
            this.formLoading = true;
            const params = this.getParams();
            saveNetDiskFile(params).then(()=>{
                this.refresh();
                this.showData = true;
                this.resetForm();
            }).finally(()=>{
                this.formLoading = false;
            })
        }
    },
    showForm (){
        if(this.isEdit){
            this.formTitle = this.$t("actions.edit");
            for(const k in this.form){
                if(k in this.currentItem){
                    this.form[k] = this.currentItem[k];
                }
            }
            this.form.type = this.form.shareType!=0;
            let users = this.form.fileUsers.split(",");
            users.forEach((item)=>{
                let staffs = this.staffList.filter((staff)=>{
                    return staff.userName == item
                })
                if(staffs.length==1){
                    this.form.users.push({userName:item,staffName:staffs[0].staffName})
                }
            })
        }else{
            this.formTitle = this.$t("actions.new");
        }
        this.showData = false;
    },   
    resetForm(){
        for(const k in this.formOld){
            this.form[k] = this.formOld[k];
        }
        this.form.users = [];
        this.uploadFileList = [];
        this.submitted = false;
    },     
    clearFile(fileName){
        this.uploadFileList.forEach(file => {
            let curFileName = JSON.parse(file.xhr.response).data[0].fileOrgName
            if(fileName==null || fileName==curFileName){
                deleteFile({fileType:this.uploadType,fileName:curFileName});
            }
        });
    },
    removeUploadFile(){
    },
    isOwner(userName){
        if(userName == this.$store.state.auth.currentUser?.userName){
            return true;
        }else{
            return false;
        }
    },
    handleSearch(){
        const params = {filter:({fileName:this.keyword})};
        queryNetDiskFile(params).then((res)=>{
            this.fileDataList = res.data;
        });
    },
    handleCreate (createType){
        this.resetForm();
        this.isEdit = false;
        this.fileType = createType;
        if(createType=="file"){
            this.form.type = true;
        }
        this.showForm();
    },
    handleEdit (item){
        this.resetForm();
        this.currentItem = item;
        this.isEdit = true;
        if(item.fileType==1){
            this.fileType = "folder";
        }else{
            this.fileType = "file";
        }
        this.showForm();
    },
    handleFormBack (){
        this.showData = true;
        this.isEdit = false;
        this.clearFile(null);
        this.resetForm();
    },
    handleClick (item){
        this.currentItem = item;
        if(item.fileType==1){
            const parentPath = this.paths[this.paths?.length-1].filePath;
            this.paths.push({text:item.fileName,filePath:parentPath+item.fileId+"/"});
            this.refresh();
        }else{
            this.handleDownload(item)
        }
    },
    handleUploadFile(){
        if(this.uploadFileList.length>0){
            const params = this.getParams();
            saveNetDiskFile(params).then(()=>{
                this.uploadFileList = [];
                this.refresh();
                this.showData = true;
            }).finally(()=>{
                this.formLoading = false;
            })
        }

    },
    // eslint-disable-next-line no-unused-vars
    handlerFileSending: function (file, xhr, formData) {
        formData.append("fileType",this.uploadType)
    },
    // eslint-disable-next-line no-unused-vars
    handlerFileSendingComplete: function (file, xhr, formData) {
        this.uploadFileList.push(file)
        const fileOrgNames = this.uploadFileList.map((item)=>JSON.parse(item.xhr.response).data[0].fileOrgName);
        this.form.fileOrgName = fileOrgNames.join();
    },
    handleFileUpload(params){
        const formData = new FormData();
        formData.append("files",params.file);
        formData.append("fileType",this.uploadType)
        return uploadFile(formData);
    },
    handleDownload (item){
        const url=`/api-gateway/service/download?fileType=${this.uploadType}&fileName=${item.fileOrgName}`
        downFile(url,item.fileName);
    },
    handleDelete (item){
        Swal.fire({
          title: this.$t("common.areyousure"),
          text: "删除后将不能恢复!",
          icon: "warning",
          confirmButtonText: this.$t("common.ok"),
          cancelButtonText: this.$t("common.cancel"),
          showCancelButton: true
        })
        .then(result => {
          if (result.value) {
            deleteNetDiskFile([item.fileId]).then(()=>{
                this.refresh();
            }).catch((error) => {
                console.info(error)
            });
          }
        });
    },
    handleChangePath (path){
        const newPaths = [];
        for(let i=0;i<this.paths.length;i++){
            newPaths.push({...this.paths[i]});
            if(this.paths[i].filePath==path.filePath){
                break;
            }
        }
        this.paths = newPaths;
        this.refresh();
    },
    iconClass,
    fileSize,
    handleInternalShare(item){        
        Vue.swal("https://workspace.greenpont.com/tools/file/"+encodeURIComponent((item.filePath+item.fileId).substr(1)));
    },
    handleShare(item){
        this.shareLinkShow = false
        this.shareForm.pwd = getUUID().substr(0,4)
        this.shareForm.fileId = item.fileId
        this.shareForm.shareType = 1
        this.shareForm.validDays = -1
        this.shareForm.upload = 0
        this.shareForm.fileType = item.fileType
        this.shareForm.fileName = item.fileName
        this.showShareModal = true
    },
    handleShareSubmit(){
        if(this.shareForm.shareType=="0"){
            this.shareForm.pwd = ""
            this.shareForm.upload = 0
        }
        shareFile(this.shareForm).then((res)=>{
            this.shareFile = res.data
            this.shareLinkShow = true
        }).catch(()=>{
            Vue.swal("创建失败");
        })
    },
    hideShareModal(){
        this.showShareModal = false
        this.shareLinkShow = false
    },
    copyToClipboard(){
        Vue.swal("复制成功");
    },
    onError(){
        Vue.swal("复制失败");
    },
    handleShareInfo(item){
        this.shareFile = item
        this.shareLinkShow = true
        this.showShareModal = true
    },
    handleDeleteShare(item){
        deleteShareFile(item).then(()=>{
            this.refreshShareList()
        })
    },
    refreshShareList(){
        queryShareList().then((res)=>{
            this.shareFileList = res.data
        })
    },
    validDaysText(days){
        if(days==-1){
            return "永久"
        }else{
            return days+"天"
        }
    }
  }
};
</script>

<template>
  <Layout>
    <div class="row align-items-center">
        <div class="col-sm-12" v-show="keyword==''">
            <b-breadcrumb class="m-0">
            <b-breadcrumb-item @click="handleChangePath(path)" v-for="path in paths" :key="path.filePath">{{path.text}}</b-breadcrumb-item>
            </b-breadcrumb>
        </div>
        <div class="col-sm-12" v-show="keyword!=''">
            <b-breadcrumb class="m-0">
                <b-breadcrumb-item @click="keyword=''" >{{$t("netdisk.pageTitle")}}</b-breadcrumb-item>
                <b-breadcrumb-item >{{$t("actions.search")}}</b-breadcrumb-item>
            </b-breadcrumb>
        </div>
    </div>       
    <div class="row">
      <div class="col-xl-9">
          <div class="card">
              <div class="card-body">
                  <div class="row mb-3" v-if="showData">
                      <div class="col-lg-5 col-sm-6">
                          <div class="search-box mb-2 me-2">
                              <div class="position-relative">
                                  <input type="search" v-model="keyword" class="form-control bg-light border-light rounded" placeholder="Search..." @keyup.enter="handleSearch">
                                  <i class="bx bx-search icon-sm search-icon"></i>
                              </div>
                          </div>
                      </div>
                      <div class="col-lg-7 col-sm-6">
                        <div class="d-flex flex-wrap align-items-start justify-content-md-end mt-2 mt-md-0 gap-2 mb-3">
                              <div>
                                  <ul class="nav nav-pills">
                                      <li class="nav-item">
                                          <a class="nav-link "  v-bind:class="showType=='list' ? 'active' : ''" data-bs-toggle="tooltip" data-bs-placement="top" title="List" @click="showType='list'"><i class="uil uil-list-ul"></i></a>
                                      </li>
                                      <li class="nav-item">
                                          <a class="nav-link" v-bind:class="showType=='grid' ? 'active' : ''" data-bs-toggle="tooltip" data-bs-placement="top" title="Grid" @click="showType='grid'"><i class="uil uil-apps"></i></a>
                                      </li>
                                  </ul>
                              </div>
                              <div>
                                <b-dropdown variant="primary" menu-class="dropdown-menu-end">
                                  <template v-slot:button-content>
                                    <i class="mdi mdi-plus me-1"></i> {{$t("actions.new")}}
                                  </template>
                                  <b-dropdown-item @click="handleCreate('folder')"><i class="mdi mdi-folder-outline me-1"></i>{{$t("netdisk.folder")}}</b-dropdown-item>
                                  <b-dropdown-item @click="handleCreate('file')"><i class="mdi mdi-file-outline me-1"></i>{{$t("netdisk.file")}}</b-dropdown-item>
                                </b-dropdown>
                                <button type="button" class="btn btn-light text-truncate ms-2"  @click="handleShareList"><i class="ion ion-md-send me-1 align-middle"></i> {{$t("netdisk.myshare")}}</button>
                                <button type="button" class="btn btn-light text-truncate ms-2"  @click="refresh"><i class="ion ion-md-refresh me-1 align-middle"></i> {{$t("actions.refresh")}}</button>                                
                              </div>                                                    
                          </div>                        
                      </div>
                  </div>           

                  <div class="row" v-if="showData && showType=='grid'">
                      <div class="col-xl-4 col-sm-6" v-for="item in fileDataList" :key="item.fileId">
                          <div class="card border">
                              <div class="card-body p-3">
                                  <div class="">
                                      <div class="d-flex justify-content-between align-items-center">
                                          <div>
                                              <i class="mdi fs-1 align-middle mb-0" :class="iconClass(item.fileName)" @click="handleClick(item)"></i>
                                          </div>
                                          <div class="avatar-group">
                                            <b-dropdown variant="link" menu-class="dropdown-menu-end">
                                                <template v-slot:button-content>
                                                    <i class="mdi mdi-dots-horizontal"></i>
                                                </template>
                                                <b-dropdown-item @click="handleDownload(item)" v-if="item.fileType==0"><i class="mdi mdi-folder-outline me-1"></i>{{$t("actions.download")}}</b-dropdown-item>
                                                <b-dropdown-item @click="handleShare(item)"><i class="mdi mdi-folder-outline me-1"></i>{{$t("actions.share")}}</b-dropdown-item>
                                                <b-dropdown-item @click="handleInternalShare(item)" v-if="item.fileType==1"><i class="mdi mdi-folder-outline me-1"></i>{{$t("actions.internalSharing")}}</b-dropdown-item>
                                                <b-dropdown-item @click="handleEdit(item)" v-if="isOwner(item.createUser)"><i class="mdi mdi-folder-outline me-1"></i>{{$t("actions.edit")}}</b-dropdown-item>
                                                <b-dropdown-item @click="handleDelete(item)" v-if="isOwner(item.createUser)"><i class="mdi mdi-file-outline me-1"></i>{{$t("actions.delete")}}</b-dropdown-item>
                                            </b-dropdown>   
                                          </div>
                                      </div>
                                      <div class="d-flex mt-3">
                                          <div class="overflow-hidden me-auto">
                                              <h5 class="font-size-15 text-truncate mb-1"><a href="javascript: void(0);" class="text-body" @click="handleClick(item)">{{item.fileName}}</a></h5>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
                  <div v-if="showData && showType=='list'">
                    <div class="mx-n4 px-4">
                        <div class="table-responsive">
                            <simplebar class="h-100">
                                <table class="table align-middle table-nowrap table-hover mb-0">
                                    <thead class="table-light">
                                        <tr>
                                            <th scope="col">{{$t("netdisk.name")}}</th>
                                            <th scope="col">{{$t("common.updateDate")}}</th>
                                            <th scope="col">{{$t("netdisk.size")}}</th>
                                            <th scope="col" colspan="2">{{$t("netdisk.owner")}}</th>
                                        </tr>
                                        </thead>
                                    <tbody>
                                        <tr v-for="item in fileDataList" :key="item.fileId">
                                            <td><a @click="handleClick(item)" style="cursor: pointer;" class="text-dark fw-medium"><i class="mdi font-size-16 align-middle me-2" :class="iconClass(item.fileName)"></i> {{item.fileName}}</a></td>
                                            <td>{{item.createDate}}</td>
                                            <td>{{fileSize(item.size)}}</td>
                                            <td>
                                                <div class="avatar-group">
                                                    <div class="avatar-group-item">
                                                        <a href="javascript: void(0);" class="d-inline-block">
                                                            <div class="avatar-sm">
                                                                <span class="avatar-title rounded-circle bg-success text-white font-size-16">
                                                                    {{item.createUser}}
                                                                </span>
                                                            </div>
                                                        </a>
                                                    </div>
                                                </div>
                                            </td>
                                            <td>
                                                <b-dropdown variant="link" menu-class="dropdown-menu-end">
                                                <template v-slot:button-content>
                                                    <i class="mdi mdi-dots-horizontal"></i>
                                                </template>
                                                <b-dropdown-item @click="handleDownload(item)" v-if="item.fileType==0"><i class="mdi mdi-folder-outline me-1"></i>{{$t("actions.download")}}</b-dropdown-item>
                                                <b-dropdown-item @click="handleShare(item)"><i class="mdi mdi-folder-outline me-1"></i>{{$t("actions.share")}}</b-dropdown-item>
                                                <b-dropdown-item @click="handleInternalShare(item)" v-if="item.fileType==1"><i class="mdi mdi-folder-outline me-1"></i>{{$t("actions.internalSharing")}}</b-dropdown-item>
                                                <b-dropdown-item @click="handleEdit(item)" v-if="isOwner(item.createUser)"><i class="mdi mdi-folder-outline me-1"></i>{{$t("actions.edit")}}</b-dropdown-item>
                                                <b-dropdown-item @click="handleDelete(item)" v-if="isOwner(item.createUser)"><i class="mdi mdi-file-outline me-1"></i>{{$t("actions.delete")}}</b-dropdown-item>
                                                </b-dropdown>   
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </simplebar>
                        </div>
                  </div>
                  </div>
                  <div class="row" v-if="!showData">
                    <div class="col-lg-12 col-sm-12" v-if="fileType=='folder' || isEdit">
                        <h4 class="card-title">{{formTitle}}</h4>
                        <form class="needs-validation" @submit.prevent="submitForm">
                        <div class="row">
                            <div class="col-md-12">
                                <div class="mb-3">
                                    <label class="form-label" for="validationCustom01">{{fileNameLabel}}</label>
                                    <input
                                        id="validationCustom01"
                                        v-model="form.fileName"
                                        type="text"
                                        class="form-control"
                                        v-bind:disable="isEdit && fileType==0"
                                        :class="{
                                            'is-invalid': $v.form.fileName.$error,
                                        }"
                                    />
                                    <div
                                    v-if="$v.form.fileName.$error"
                                    class="invalid-feedback"
                                    >
                                        <span v-if="!$v.form.fileName.required">
                                            名称不能为空.
                                        </span>
                                        <span v-if="!$v.form.fileName.isUnique">
                                            名称已存在.
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-12">
                                <div class="mb-3">
                                    <label class="form-label">{{$t("netdisk.discription")}}</label>
                                    <textarea
                                        v-model="form.description"
                                        class="form-control"
                                    >
                                    </textarea>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-12">
                                <div class="mb-3">
                                    <label class="form-label">{{$t("netdisk.ispublic")}}</label>
                                    <switches v-model="form.type" type-bold="false" color="info" style="display: block;"></switches>
                                </div>
                            </div>
                        </div>
                        <div class="row" v-if="!form.type">
                            <div class="col-md-12">
                                <div class="mb-3">
                                    <label class="form-label">{{$t("netdisk.specifiedusers")}}</label>
                                    <multiselect v-model="form.users" :options="staffList" :multiple="true"
                                        label="staffName"
                                        track-by="userName"
                                    ></multiselect>
                                </div>
                            </div>
                        </div>
                        <button type="submit" class="btn btn-primary">{{$t("actions.submit")}}</button>
                        <button type="button" class="btn btn-success  ms-1" @click="handleFormBack">{{$t("actions.back")}}</button>
                        </form>
                    </div>
                    <div class="col-lg-12 col-sm-12" v-if="fileType=='file' && !isEdit">
                        <h4 class="card-title">{{$t("netdisk.uploadfile")}}</h4>
                        <div class="mb-5">
                        <!-- file upload -->
                        <vue-dropzone id="dropzone" ref="myVueDropzone" :options="dropzoneOptions"
                        @vdropzone-sending="handlerFileSending"
                        @vdropzone-complete="handlerFileSendingComplete"
                        @removeUpload="removeUploadFile"
                        ></vue-dropzone>
                        </div>
                        <div class="text-center mt-3">
                            <button type="button" class="btn btn-primary" @click="handleUploadFile">{{$t("actions.submit")}}</button>
                            <button type="button" class="btn btn-success  ms-1" @click="handleFormBack">{{$t("actions.back")}}</button>
                        </div>
                    </div>
                  </div>
                  <div class="row" v-if="showData && showType=='shareList'">
                    <div class="table-responsive">
                        <simplebar class="h-100">
                            <table class="table align-middle table-nowrap table-hover mb-0">
                                <thead class="table-light">
                                    <tr>
                                        <th scope="col">{{$t("netdisk.shareName")}}</th>
                                        <th scope="col">{{$t("netdisk.shareDate")}}</th>
                                        <th scope="col">{{$t("netdisk.expiryDate")}}</th>
                                        <th scope="col" >{{$t("actions.actions")}}</th>
                                    </tr>
                                    </thead>
                                <tbody>
                                    <tr v-for="item in shareFileList" :key="item.id">
                                        <td>{{item.fileName}}</td>
                                        <td>{{item.createDate}}</td>
                                        <td>{{validDaysText(item.validDays)}}</td>
                                        <td>
                                            <a href="javascript:" @click="handleShareInfo(item)">{{$t("netdisk.shareInformation")}}</a> <a href="javascript:" @click="handleDeleteShare(item)">{{$t("actions.delete")}}</a>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </simplebar>
                    </div>
                  </div>
              </div>
          </div>
      </div>
      <div class="col-xl-3">
      <div class="card">
          <div class="card-body">
                  <div class="">
                      <div>
                          <h5 class="font-size-17 mb-3 pt-2">Recent Files</h5>
                          <div class="card border shadow-none mb-2" v-for="(value, name) in summaryObj" :key="name">
                                  <div class="p-2">
                                      <div class="d-flex align-items-center">
                                          <div class="avatar align-self-center me-3">
                                              <div class="avatar-title rounded bg-soft-success  font-size-24" :class="iconClass(value.fileType)[1]">
                                                  <i class="mdi" :class="iconClass(value.fileType)[0]"></i>
                                              </div>
                                          </div>

                                          <div class="overflow-hidden me-auto">
                                              <h5 class="font-size-15 text-truncate mb-1">{{name}}</h5>
                                              <p class="text-muted text-truncate mb-0">{{value.files}} Files</p>
                                          </div>

                                          <div class="ms-2">
                                              <p class="text-muted">{{fileSize(value.size)}}</p>
                                          </div>
                                      </div>
                                  </div>
                          </div>
                      </div>
                  </div>
          </div>
      </div>
      </div>
      <b-modal
        centered
        v-model="showShareModal"
        :title="$t('actions.share')"
        title-class="text-black font-18"
        body-class="p-4"
        hide-footer
      >
        <form @submit.prevent="handleShareSubmit" v-if="!shareLinkShow">
          <div class="row">
            <div class="col-12">
              <div class="mb-3">
                <label class="form-label mb-3 d-flex">{{$t("netdisk.shareType")}}</label>
                <div class="form-check form-check-inline">
                    <input
                        type="radio"
                        id="shareType1"
                        name="shareType"
                        class="form-check-input"
                        value="1"
                        v-model="shareForm.shareType"
                    />
                    <label class="form-check-label" for="shareType1">{{$t("netdisk.needCode")}}</label>
                </div>
                <div class="form-check form-check-inline">
                    <input
                        type="radio"
                        id="shareType2"
                        name="shareType"
                        class="form-check-input"
                        value="0"
                        v-model="shareForm.shareType"
                    />
                    <label class="form-check-label" for="shareType2">{{$t("netdisk.noNeedCode")}}</label>
                </div>
              </div>
              <div class="mb-3" v-if="shareForm.shareType==1">
                <label class="form-label mb-3 d-flex" for="pwd">{{$t("netdisk.code")}}</label>
                <input
                        id="pwd"
                        name="pwd"
                        type="text"
                        class="form-control"
                        v-model="shareForm.pwd"
                        :class="{ 'is-invalid': !checkPwd }"
                />
                <div
                  v-if="!checkPwd"
                  class="invalid-feedback"
                >
                  {{$t("netdisk.errorNeedCode")}}
                </div>  
              </div>
              <div class="mb-3" v-if="shareForm.shareType==1">
                <label class="form-label mb-3 d-flex">{{$t("netdisk.allowUploading")}}:</label>
                <div class="form-check form-check-inline">
                <input
                    type="radio"
                    id="upload1"
                    name="upload"
                    class="form-check-input"
                    value="1"
                    v-model="shareForm.upload"
                />
                <label class="form-check-label" for="upload1">{{$t("common.yes")}}</label>
                </div>
                <div class="form-check form-check-inline">
                <input
                    type="radio"
                    id="upload2"
                    name="upload"
                    class="form-check-input"
                    value="0"
                    v-model="shareForm.upload"
                />
                <label class="form-check-label" for="upload2">{{$t("common.no")}}</label>
                </div>
              </div>
              <div class="mb-3">
                <label class="form-label mb-3 d-flex">{{$t("netdisk.expiryDate")}}:</label>
                <div class="form-check form-check-inline">
                <input
                    type="radio"
                    id="validDays1"
                    name="validDays"
                    class="form-check-input"
                    value="-1"
                    v-model="shareForm.validDays"
                />
                <label class="form-check-label" for="validDays1">{{$t("netdisk.permanent")}}</label>
                </div>
                <div class="form-check form-check-inline">
                <input
                    type="radio"
                    id="validDays2"
                    name="validDays"
                    class="form-check-input"
                    value="30"
                    v-model="shareForm.validDays"
                />
                <label class="form-check-label" for="validDays2">30 {{$t("netdisk.day")}}</label>
                </div>
                <div class="form-check form-check-inline">
                <input
                    type="radio"
                    id="validDays3"
                    name="validDays"
                    class="form-check-input"
                    value="7"
                    v-model="shareForm.validDays"
                />
                <label class="form-check-label" for="validDays3">7 {{$t("netdisk.day")}}</label>
                </div>
                <div class="form-check form-check-inline">
                <input
                    type="radio"
                    id="validDays4"
                    name="validDays"
                    class="form-check-input"
                    value="1"
                    v-model="shareForm.validDays"
                />
                <label class="form-check-label" for="validDays4">1 {{$t("netdisk.day")}}</label>
                </div>
              </div>
            </div>
          </div>

          <div class="mt-2 text-end">
            <b-button variant="light" @click="hideShareModal">{{$t("common.cancel")}}</b-button>
            <b-button type="submit" variant="success" class="ms-1" 
              >{{$t("netdisk.create")}}</b-button
            >
          </div>
        </form>
        <div v-if="shareLinkShow">
            <div class="mb-2"><strong>{{$t("netdisk.shareLink")}}</strong>：{{ shareLink }}</div>
            <div v-if="shareLinkPwd!=''" class="mb-2"><strong>{{$t("netdisk.code")}}</strong>：{{ shareLinkPwd }}</div>
            <div ><b-button v-if="false" variant="light"  v-clipboard:copy="shareLinkText" v-clipboard:success="copyToClipboard" v-clipboard:error="onError">{{$t("actions.copy")}}</b-button><b-button variant="light" @click="hideShareModal" class="ms-1">{{$t("actions.close")}}</b-button></div>
        </div>
      </b-modal>
  </div>
  </Layout>
</template>
<style lang="scss" scoped>
.border-light {
  border-color: #f8f7fc !important;
}
.bg-light {
    background-color: rgba(248, 247, 252,1)!important;
}
.search-box{
  .form-control{
      border-radius: 30px;
      padding-left: 40px;
  }
  .search-icon{
      font-size: 16px;    
      position: absolute;
      left: 13px;
      top: 2px;
      color: #959396;
      line-height: 38px;
  }
}
</style>